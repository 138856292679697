import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import BOALogo from "../../images/pageImages/Homepage/14.png";
import AvivaLogo from "../../images/pageImages/Homepage/15.png";

const StyledWrapper = styled.div`
  .section-heading {
    color: blue;
  }

  .img-class {
    height: 150px;
    width: 400px;
    object-fit: scale-down;
  }

  .sponsor-align {
    text-align: center;
  }

  .sponsor-title {
    text-align: left;
  }

  .description {
    text-align: left;
    font-size: 17px;
    line-height: 24px;
  }

  .description a {
    color: black;
    text-decoration: underline;
  }
  .section-heading {
    text-transform: uppercase;
  }
`;

export default function SponsorList({ data, heading }) {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper className="container-fluid padding-left-8 padding-right-8 py-5">
      <h1 className="pb-5">{heading}</h1>
      {data.filter(i => i.SponsorType === "Platinum").length > 0 && (
        <div>
          <div>
            {data
              .filter(i => i.SponsorType === "Platinum")
              .map(i => (
                <div className="row sponsor-align">
                  <h2 className="section-heading pt-4">
                    {i.SponsorType} {i.type}
                  </h2>
                  <div className="col-12 py-3">
                    <div className="my-5 img-div d-flex justify-content-center align-items-center">
                      {i.Image && (
                        <img
                          className="img-fluid img-class"
                          src={mainUrl + i.Image.url}
                        />
                      )}
                    </div>
                    <h2 className="sponsor-title">{i.Title}</h2>
                    <div className="description">
                      <ReactMarkdown source={i.Description} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {data.filter(i => i.SponsorType === "Gold").length > 0 && (
        <div>
          {data
            .filter(i => i.SponsorType === "Gold")
            .map(i => (
              <div className="row sponsor-align">
                <h2 className="section-heading pt-4">
                  {i.SponsorType} {i.type}
                </h2>
                <div className="col-12 py-3">
                  <div className="my-5 img-div d-flex justify-content-center align-items-center">
                    {i.Image && (
                      <img
                        className="img-fluid img-class"
                        src={mainUrl + i.Image.url}
                      />
                    )}
                  </div>
                  <h2 className="sponsor-title">{i.Title}</h2>
                  <div className="description">
                    <ReactMarkdown source={i.Description} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      {data.filter(i => i.SponsorType === "Silver").length > 0 && (
        <div>
          <div>
            {data
              .filter(i => i.SponsorType === "Silver")
              .map(i => (
                <div className="row sponsor-align">
                  <h2 className="section-heading pt-4">
                    {i.SponsorType} {i.type}
                  </h2>
                  <div className="col-12 py-3">
                    <div className="my-5 img-div d-flex justify-content-center align-items-center">
                      {i.Image && (
                        <img
                          className="img-fluid img-class"
                          src={mainUrl + i.Image.url}
                        />
                      )}
                    </div>
                    <h2 className="sponsor-title">{i.Title}</h2>
                    <div className="description">
                      <ReactMarkdown source={i.Description} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {data.filter(i => i.SponsorType === "Bronze").length > 0 && (
        <div>
          <div>
            {data
              .filter(i => i.SponsorType === "Bronze")
              .map(i => (
                <div className="row sponsor-align">
                  <h2 className="section-heading pt-4">
                    {i.SponsorType} {i.type}
                  </h2>
                  <div className="col-12 py-3">
                    <div className="my-5 img-div d-flex justify-content-center align-items-center">
                      {i.Image && (
                        <img
                          className="img-fluid img-class"
                          src={mainUrl + i.Image.url}
                        />
                      )}
                    </div>
                    <h2 className="sponsor-title">{i.Title}</h2>
                    <div className="description">
                      <ReactMarkdown source={i.Description} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </StyledWrapper>
  );
}
