import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "../components/layout";
import styled from "styled-components";
import SEO from "../components/seo";
import SponserDesc from "../components/Sponser/SponserDescription";
import sponserlogo1 from "../images/pageImages/Homepage/14.png";
import sponserlogo2 from "../images/pageImages/Homepage/15.png";
import Testimonial from "../components/Reusables/Testimonials";
import SummaryBlock from "../components/Reusables/SummaryBlock";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SponsorForm from "../components/Sponser/Form1";
import SponsorModal from "../components/Sponser/SponsorModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SponsorList from "../components/Sponser/Sponsor";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .margin-bot {
    margin-bottom: 100px;
  }

  .bottom-div {
    color: ${props => props.theme["borderBottomColor"]};
  }

  .end-div {
    font-size: 48px;
    color: ${props => props.theme["borderBottomColor"]};
    text-decoration: underline;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }

  .toast-color {
    background: #f2b842;
  }

  .progressClass {
    background: white;
  }

  .toastBodyClass {
    color: black;
  }
`;

const body = (
  <div>
    <p>
      Our sponsors are passionate about boosting social mobility. Their generous
      support has enabled the Student Social Mobility Awards to grow from a
      great idea into a major celebration of the achievements of undergraduates
      from across the UK.
    </p>
    <p>
      Sponsors can partner with the SSMAs in a number of ways, depending on
      their priorities. Potential benefits include branding opportunities across
      the suite of promotional materials, complimentary tickets to the event and
      the chance to present an Award.
    </p>
  </div>
);

const data = {
  title: "Our Sponsors",
  description: body,
  cta: "Download our sponsor pack for more information",
  href: "",
  sponsers: [
    {
      image: `${sponserlogo1}`,
      title: "Bank of America",
      description:
        "At Bank of America, we’re guided by a common purpose to help make financial lives better. We’re delivering on this through responsible growth with a focus on our environmental, social and governance (ESG) leadership. ESG is embedded across our business and reflects how we help fuel the global economy, build trust and credibility, and represent a company that people want to work for, invest in and do business with. It’s demonstrated in the inclusive and supportive workplace we create for our employees, the responsible products and services we offer our clients, and the impact we make around the world in helping local economies thrive. An important part of this work is forming strong partnerships with not-for-profits and advocacy groups, such as community, consumer and environmental organisations, to bring together our collective networks and expertise to achieve greater impact. Learn more at about.bankofamerica.com, and connect with us on Twitter @BofA_Business.",
    },
    {
      image: `${sponserlogo2}`,
      title: "Aviva",
      description:
        "At Aviva, we help our customers look to the future with confidence. To do that, we have to be a responsible, sustainable business so we can help protect and improve the future for everyone. As a global company that’s been around for over 300 years, we’re committed to running our business responsibly everyday. This includes respecting human rights, managing supply chains responsibly and practicing good governance. We have many different products to support people with life’s everyday challenges that affect both them and their communities. This means we’re well placed to help tackle the big global challenges too. And by using digital technology, we can reach more people, faster. Learn more at aviva.com and connect with us on twitter @Avivaplc",
    },
  ],
};

const carousel = [
  {
    description:
      "Being an Associate helps you set out your career goals and builds your confidence to apply.",
    author: "- Alfie, Senior Associate at KPMG",
  },
  {
    description:
      "I couldn’t have got my offer at Deloitte without upReach and my mentor at the firm.",
    author: "- Kim, Tax Associate at Deloitte",
  },
];
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Sponsors />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/sponsorpage`);
};

const Sponsors = () => {
  const [modalOpen, setModalOpen] = React.useState();
  const sponsorRef = useRef(null);
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("sponsor", fetchData);

  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);

  const notifyToast = () => {
    return toast("Your details have been submitted", {
      className: "toast-color",
      progressClassName: "progressClass",
      bodyClassName: "toastBodyClass",
    });
  };

  useEffect(() => {
    if (window.location.href.includes("isSponsor=true")) {
      setModalOpen(true);
    }
    if (modalOpen === false) {
      window.history.replaceState({}, document.title, "/sponsors");
      setModalOpen(false);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (newData && newData.showSponsorSummary === true) {
      sponsorRef.current.children[0].children[0].children[0].children[0].children[0].children[3].onclick = function (
        event
      ) {
        ReactGA.event({
          category: "Sponsors Page",
          action: "Get in touch to find out more Button Click",
        });
      };
    }
  }, []);

  const handleClick = () => {
    ReactGA.event({
      category: "Sponsors Page",
      action: "Get in Touch Button Click",
    });
  };

  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} />}

        {newData && newData.showSponsorSummary && (
          <section className="white-background" ref={sponsorRef}>
            {newData.SponsorSummary && (
              <SummaryBlock summary={newData.SponsorSummary} />
            )}
          </section>
        )}
        {newData &&
          newData.showSponsorMain &&
          newData.SponsorMain.length > 0 && (
            <SponsorList
              data={newData.SponsorMain}
              heading={newData.SponsorHeading}
            />
          )}
        {newData && newData.ShowPreviousSponsor && (
          <div className="white-background">
            {newData.PreviousSponsor && (
              <SponserDesc data={newData.PreviousSponsor} />
            )}
          </div>
        )}
        {newData && newData.ShowTestimonial && (
          <section className="white-background">
            {newData.Testimonial.length > 0 && (
              <Testimonial data={newData.Testimonial} carousel={carousel} />
            )}
          </section>
        )}
        {newData && newData.CtaButton && (
          <div
            className="container-fluid bottom-div mt-0"
            style={{ background: newData && newData.CtaButton.bgColor }}
          >
            <div className="col-12 py-4 d-flex justify-content-center">
              <AnchorLink
                to={newData.CtaButton.link}
                className="end-div"
                onAnchorLinkClick={handleClick}
              >
                {" "}
                {newData.CtaButton.title}
              </AnchorLink>
            </div>
          </div>
        )}

        <ToastContainer />
        {modalOpen && (
          <SponsorModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            notify={notifyToast}
          />
        )}
      </StyledWrapper>
    </Layout>
  );
};
