import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .link-tag {
    text-decoration: underline;
    color: black;
    font-size: 24px;
    font-weight: 800;
  }

  .image-container {
    height: 100px;
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      height: 100px;
    }
    @media (max-width: 768px) {
      height: 75px;
    }
  }

  .desc {
    font-size: 17px;
    font-weight: 400px;
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .header-text strong {
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-size: 32px;
    font-weight: 900;
  }

  .header-text a {
    text-decoration: underline;
    color: black;
    font-size: 24px;
    font-weight: 800;
  }

  .img-class {
    height: 50px;
    object-fit: contain;
    @media (max-width: 600px) {
      height: 60px;
    }
  }
`;

const SponserDesc = ({ data }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper className="padding-left-8 padding-right-8 py-5">
      <p className="header-text col-lg-6 pl-0">
        <ReactMarkdown source={data.header} />
      </p>
      <div className="row">
        {data.sponsorCompanies.length > 0 &&
          data.sponsorCompanies.map(item => (
            <div className="col-6 col-md-2 col-lg-2 mt-3">
              <div className="image-container">
                {item.image && (
                  <img
                    className="img-class img-fluid mx-auto"
                    src={mainUrl + item.image.url}
                    alt=""
                  />
                )}
              </div>

              <div>
                <div className="py-3 header-text">
                  {item.description && (
                    <ReactMarkdown source={item.description} />
                  )}
                </div>
                {/* <div className="desc">{item.description}</div> */}
              </div>
            </div>
          ))}
      </div>
    </StyledWrapper>
  );
};

export default SponserDesc;
