import React from "react";
import ReactMarkdown from "react-markdown";
import Slider from "react-slick";
import styled from "styled-components";
import carouselArrowRight from "../../images/carousel-arrow.svg";
import carouselArrowLeft from "../../images/carousel-left-arrow.svg";

const StyledWrapper = styled.div`
  // Carousel Css

  .slider-slick {
    max-width: ${props => props.theme["percent-90"]};
    margin: auto;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }

  .slick-prev {
    left: -6.7%;
    z-index: 1;
    background-color: black !important;
  }

  .slick-next {
    right: -6.7%;
    background-color: black;
  }

  .slick-next:hover,
  .slick-prev:hover {
    background-color: black;
  }

  .slick-prev:before {
    content: url(${carouselArrowLeft});
    transform: rotate(180deg);
  }

  .slick-next:before {
    content: url(${carouselArrowRight});
  }

  .background-color {
    background-color: #f2b842;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .testimonial {
    color: #000000;
    font-size: 24px;
    font-weight: lighter;
    letter-spacing: 0px;
    line-height: 42px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .author {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .testimonial strong {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 16px;
    color: #000000;
  }

  .testimonial-image {
    flex-shrink: 0;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

const Testimonial = ({ data }) => {
  var settings = {
    speed: 500,
    slidesToShow: 1,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper className="py-5">
      <div className="container-fluid">
        <Slider {...settings} className="slider-slick">
          {React.Children.toArray(
            data.length > 0 &&
              data.map(item => (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-md-4 p-0 boxHeight">
                      {item.image && (
                        <img
                          className="img-fluid testimonial-image"
                          src={mainUrl + item.image.url}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-8 background-color padding-left-8 padding-right-8 boxHeight">
                      <div className="testimonial">
                        {" "}
                        {item.description && (
                          <ReactMarkdown source={item.description} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}
        </Slider>
      </div>
    </StyledWrapper>
  );
};

export default Testimonial;
